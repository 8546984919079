import React, { useState, useEffect } from "react";
import FormStep from "./FormStep";
import MuiCustomStepper from "./MuiCustomStepper";
import * as yup from "yup";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { useNavigate } from "react-router-dom";
import DefaultSnackbar from "components/Snackbar";
import {
  useGetSpecialServiceQuery,
  useTicketCheckoutMutation,
} from "features/ticket/ticketApiSlice";
import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import useGetDataPagination from "hooks/useGetDataPagination";

import { Box } from "@mui/material";
import PriceSelect from "./priceSelect";
import moment from "moment";
//import { is } from "date-fns/locale";

const CheckoutWebsite = ({
  id,
  price,
  name,
  setModalData,
  eventId,
  tickets,
  isFree,
  bundle,
  currency,
  isRecurring,
}) => {
  const [error, setError] = useState(null);
  const [useEmail, setUseEmail] = useState(true);
  const validationSchema = yup.object({
    // price: isFree ? yup.number() : yup.number().required("Price is required"),
    // specialService: yup.boolean(),
    // event_schedule_id: isRecurring ? yup.number().required() : null,
    // is_ticket_bundled: yup.boolean(),
    // ticket_bundle_id: yup.number(),
    // quantity: yup
    //   .number()
    //   .required("Quantity is required")
    //   .min(1, "Quantity cannot be less than 1")
    //   .max(10, "You cant purchase more than 10 tickets at a time"),
    pMethod: isFree
      ? null
      : yup.string().required("Payment method is required"),
    // buyer_full_name: yup.string().required("Full name is required"),
    date_of_birth: yup.string().required("Birth Date is required"),
    // buyer_email: useEmail
    //   ? yup.string().required("Email is required").email("Enter a valid email")
    //   : yup.string().nullable(),
    // buyer_phone: useEmail
    //   ? yup.string().nullable()
    //   : yup.string().required("Phone number is required"),
  });
  const [snackOptions, setSnackOptions] = useState({ open: false, text: "" });
  const navigate = useNavigate();
  const [pricee, setPrice] = useState(0);
  const [bundlee, setBundlee] = useState(false);

  const [currencyy, setCurrency] = useState("ETB");

  const [ticket, setTicket] = useState(id);

  const [snapshot, setSnapshot] = useState({
    price: price,
    specialService: "",
    quantity: 1,
    pMethod: "",
    buyer_full_name: "",
    date_of_birth: "",
    buyer_email: "",
    event_schedule_id: "",
    buyer_phone: "",
    promocode: "",
  });
  const [ticketCheckout] = useTicketCheckoutMutation();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };
  const { data: specialService, isLoading } = useGetSpecialServiceQuery({
    id: eventId,
  });

  const {
    isLoading: isLoadingSchedule,
    pagination: paginationSchedule,
    setPagination: setPaginationSchedule,
    rowCount: rowCountSchedule,
    data: schedule,
    setEventId: setEventIdSchedule,
  } = useGetDataPagination()(useGetEventScheduleQuery);
  useEffect(() => {
    setEventIdSchedule(eventId);
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    const ScheduleId = localStorage.getItem("selectedScheduleId");

    const formData = new FormData();
    try {
      setError(null);
      bundle || bundlee
        ? formData.append("is_ticket_bundled", true)
        : formData.append("is_ticket_bundled", false);
      price == -1
        ? bundle || bundlee
          ? formData.append("ticket_bundle_id", ticket)
          : formData.append("ticket_id", ticket)
        : bundle || bundlee
        ? formData.append("ticket_bundle_id", id)
        : formData.append("ticket_id", id);
      formData.append("payment_method_identifier", values.pMethod);
      formData.append("buyer_full_name", values.buyer_full_name);
      const date = moment(values.date_of_birth);
      const formattedDate = date.format("YYYY-MM-DD");
      formData.append("date_of_birth", formattedDate);

      values.buyer_email != "" &&
        formData.append("buyer_email", values.buyer_email);
      values.buyer_phone != "" &&
        formData.append("buyer_phone", values.buyer_phone);
      price == -1
        ? formData.append("currency", currencyy)
        : formData.append("currency", currency);

      formData.append("quantity", values.quantity);
      specialService &&
        specialService?.length !== 0 &&
        formData.append("special_service", values.specialService);

      isRecurring && formData.append("event_schedule_id", ScheduleId);
      values.promocode && formData.append("promo_code", values.promocode);

      const res = await ticketCheckout(formData);

      setSubmitting(false);
      if (res?.error) {
        if (res.error.originalStatus === 401) {
          throw new Error("Please login first to make a payment.");
        }
        throw new Error(res.error.data.message);
      }
      if (isFree) {
        setSnackOptions({
          open: true,
          text: "Successfully placed an order. You will recieve your ticket via the email/phone you have provided",
        });
        setTimeout(
          () => setModalData((prev) => ({ ...prev, open: false })),
          1000
        );
      } else {
        setSnackOptions({
          open: true,
          text: "Successfully placed an order. Page will redirect.",
        });
        setTimeout(() => window.location.replace(res.data.data), 2000);
      }
    } catch (e) {
      setError(e.message);
    }
  };
  return (
    <Box px={{ xl: 3, lg: 3, md: 0, xs: 0 }}>
      <MuiCustomStepper
        desc={`${name} Ticket Purchase`}
        title="Checkout"
        onBack={() => setModalData((prev) => ({ ...prev, open: false }))}
        snapshot={snapshot}
        setSnapshot={setSnapshot}
        error={error}
        ticket={ticket}
      >
        {price == -1 && (
          <FormStep stepName="Choose Ticket">
            <PriceSelect
              tickets={tickets}
              eventId={eventId}
              price={pricee}
              ticket={ticket}
              currencyy={currencyy}
              setPrice={setPrice}
              setBundle={setBundlee}
              setTicket={setTicket}
              setCurrency={setCurrency}
              isRecurring={isRecurring}
            />
          </FormStep>
        )}
        <FormStep validationSchema={validationSchema} stepName="Payment info">
          <PaymentForm
            ticket={ticket}
            eventId={eventId}
            isRecurring={isRecurring}
            bundle={bundle || bundlee}
            specialService={specialService}
            useEmail={useEmail}
            currency={price == -1 ? currencyy : currency}
            setUseEmail={setUseEmail}
            isFree={isFree}
            price={price == -1 ? pricee : price}
            setSnapshot={setSnapshot}
          />
        </FormStep>
        <FormStep
          validationSchema={validationSchema}
          stepName="Review"
          onSubmit={handleSubmit}
        >
          <Review
            snapshot={{
              ...snapshot,
              pricee,
              currency: price == -1 ? currencyy : currency,
              setError,
              id,
              isFree,
              reason: `${name} ticket payment`,
              ticket,
            }}
          />
        </FormStep>
      </MuiCustomStepper>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default CheckoutWebsite;
